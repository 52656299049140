<template>
  <div>
    <div class="profileContainer">
      <img src="/images/avatar.jpg">
      <div class="IDContainer">
        <h3>Twój profil</h3>
        <h4>ID: {{ $store.state.userID }}</h4>
        <Button @click="this.$store.commit('displayIDPrompt')">Zmień ID</Button>
      </div>
    </div>
  <h2>Twoje nagrody:</h2>
  <p v-if="$store.state.allRewards.length == 0">Wygląda na to, że jeszcze nie zdobyłeś żadnych nagród. Szukaj kodów, żeby je zdobyć!</p>
  <DataTable v-if="$store.state.allRewards.length > 0" :value="$store.state.allRewards" stripedRows>
    <Column field="reward_name" header="Nazwa">
    </Column>
    <Column field="rarity" header="Kategoria">
      <template #body="{data}">
        {{ data.rarity }} ⭐
      </template>
    </Column>
  </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'

export default {
  data(){
    return {
    }
  },
  mounted(){
    this.$store.dispatch('syncStatus')
  },
  methods: {
  },
  components: {
    DataTable,
    Column,
    Button
  }
}
</script>

<style lang="scss" scoped>
.profileContainer {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  justify-content: space-evenly;
  align-items: center;
}
.profileContainer img {
  max-width: 40vw;
}
</style>